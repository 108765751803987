<template>
    <div class="trip__accordion" v-if="translates && translates[langUrl]">
        <div v-if="!hideButtons" class="trip__accordion__buttons">
            <div class="trip__accordion__buttons__button" @click="all">{{ translates[langUrl].button_show[lang] }}</div>
            <div class="trip__accordion__buttons__button" @click="none">{{ translates[langUrl].button_hide[lang] }}</div>
        </div>
        <v-expansion-panels accordion v-model="panel" multiple>
            <v-expansion-panel v-for="(item, index) in items" :key=" 'program-' + index">
                <v-expansion-panel-header>{{ titleFormatted(item.title, index) }}</v-expansion-panel-header>
                <v-expansion-panel-content>{{ item.description }}</v-expansion-panel-content>
                <v-expansion-panel-content>
                    <div class="trip__accordion__gallery" v-if="isDesktop && item.pictures.length > 0 && item.pictures.length <= 3">
                        <template v-for="(picture, i) in item.pictures"> 
                            <div :key="'picture-' + i">
                                <div class="trip__accordion__picture__wrapper">
                                    <div class="trip__accordion__picture" :style="`background-image: url(${ imageSrc(picture) })`"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="trip__accordion__gallery" v-else-if="!isDesktop && item.pictures.length === 1">
                        <template v-for="(picture, i) in item.pictures"> 
                            <div :key="'picture-' + i">
                                <div class="trip__accordion__picture__wrapper">
                                    <div class="trip__accordion__picture" :style="`background-image: url(${ imageSrc(picture) })`"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="trip__accordion__gallery" v-else>
                        <!-- <Splide
                            class="trip__accordion__gallery"
                            :id="`trip-accordion-gallery-${trip._id}-${index}`"
                            :options="{ rewind: true, type: 'loop', perPage: 3, fixedWidth: '330px', perMove: 1, gap: 10 }">
                            <template v-for="(picture, i) in item.pictures">
                                <SplideSlide :key="'picture-' + i">
                                    <div class="trip__accordion__picture__wrapper">
                                        <div class="trip__accordion__picture" :style="`background-image: url(${ imageSrc(picture) })`"></div>
                                    </div>
                                </SplideSlide>
                            </template>
                        </Splide> -->
                        <template v-for="(picture, i) in item.pictures">
                            <div class="trip__accordion__picture__wrapper" :key="'picture-' + i">
                                <div class="trip__accordion__picture" :style="`background-image: url(${ imageSrc(picture) })`"></div>
                            </div>
                        </template>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import { mapState } from 'vuex';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import BaseButton from '../../common/BaseButton.vue'

    export default {
        name: 'TripAccordion',
        components: {
            Splide,
            SplideSlide,
            BaseButton
        },
        props: {
            // items: { 
            //     type: Array, 
            //     default: () => []
            // },
            isDesktop: {
                type: Boolean,
                default: true
            },
            openAllItems: {
                type: Boolean,
                default: true
            },
            hideButtons: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            panel: [],
            langUrl: '/trip_program'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            items() {
                this.panel = this.trip.programs.map((k, i) => i);
                return this.trip.programs;
            }
        },
        async mounted() {
            this.isOpenItems();
        },
        methods: {
            all () {
                this.panel = [...this.items.keys()].map((k, i) => i)
            },
            none () {
                this.panel = []
            },
            isOpenItems() {
                if(this.openAllItems === true) {
                    for (let i=0; i<this.items.length; i++) {
                        this.panel.push(i)
                    }
                }
            },
            titleFormatted(title, index) {
                if(this.lang === 'ru') {
                    return `День ${ index + 1 }. ${ title }`;
                } else {
                    return `Day ${ index + 1 }. ${ title }`;
                }
            },
        }
    }
</script>

<style lang="scss">
    .trip__accordion {
        display: flex;
        flex-direction: column;
        // border-bottom: 1px #b0b5c4 solid;
        padding-top: 10px;
        &__buttons {
            display: flex;
            flex-direction: row;
            // align-items: flex-end;
            justify-content: flex-end;
            :first-child {
                margin-right: 20px;
            }
            &__button {
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #D80765;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        &__program {
            padding: 10px 0;
        }
        &__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #3A4256;
            @media all and (min-width: 768px) {
                font-weight: normal;
                font-size: 20px;
                line-height: 150%;
            }
        }

        &__description {
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
        &__gallery{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 16px;
            margin-right: -10px;
            padding-bottom: 7px;
        }
        &__picture {
            width: 100%;
            height: 100%;
            position: relative;
            background-size: cover;
            background-position: center;
            border-radius: 8px;

            &__wrapper {
                width: 285px;
                height: 180px;
                margin: 0 10px 10px 0;
                position: relative;
                background-size: cover;
                background-position: center;
                border-radius: 8px;
            }
        }
        .v-expansion-panel-content {
            max-width: 1015px;
        }
        .v-expansion-panels {
            border: none;

            &::before { 
                content: "";
                border-left: 1px dashed #C3C8CC;
                position: absolute;
                top: 30px;
                left: 5px;
                height: calc(100% - 60px);
            }
            .v-expansion-panel:not(:last-child) {
                background: none;
            }
            .v-expansion-panel:last-child::before { 
                content: "";
                border-left: 1px dashed #C3C8CC;
                position: absolute;
                top: 0;
                left: 5px;
                height: 30px;
                opacity: 1;
                transition: none;
                box-shadow: none;
                z-index: 0;
            }
        }
        .v-expansion-panel {
            border: none;
            margin: 0;

            &::before {
                content: none;
            }
            &::after {
                margin-left: 26px;
            }
        }
        .v-expansion-panel-header {
            padding: 20px 0 20px 26px;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #3A4256;
            background: url(../../../assets/AccordionCircle.svg) 0 center no-repeat no-repeat;
            @media all and (min-width: 768px) {
                font-weight: normal;
                font-size: 20px;
                line-height: 150%;
                padding: 20px 0 20px 31px;
            }
            &--active {
                background-image: url(../../../assets/AccordionCircleActive.svg);
                color: #DA0A63;

                .v-expansion-panel-header__icon .v-icon {
                    color: #DA0A63 !important;
                }
            }
        }
        .v-expansion-panel-content {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
            @media all and (min-width: 768px) {
                line-height: 180%;
            }

            &__wrap {
                padding: 0 0 20px 26px;
            }
        }
    }
</style>