<template>
    <div class="trip__arrivals" v-if="translates && translates[langUrl]">
        <template v-for="(arrival, i) in arrivals">
            <div :key="'arrival' + i" class="trip__arrivals__arrival">
                <div class="trip__arrivals__arrival__dates">
                    <div class="trip__arrivals__arrival__dates__start">
                        <div class="trip__arrivals__arrival__dates__day">{{ dateFormated(arrival.start) }}</div>
                        {{ arrival.start | moment('DD MMM YYYY') }}</div>
                    <img src="../../../assets/ArrowCircleRight.svg" />
                    <div class="trip__arrivals__arrival__dates__finish">
                        <div class="trip__arrivals__arrival__dates__day">{{ dateFormated(arrival.finish) }}</div>
                        {{ arrival.finish | moment('DD MMM YYYY') }}</div>
                </div> 
                <div class="trip__arrivals__arrival__price">
                    <div class="trip__arrivals__arrival__price__value"><span v-html="priceFormated(arrival.price, arrival.currency)"></span> </div>
                </div>
                <div class="trip__arrivals__arrival__info">{{ translates[langUrl].offer[lang] }}</div>
                <BaseButton
                    v-if="checkArrival(arrival)"
                    class="button"
                    style="width: 100%"
                    @click="payBooking(arrival)">{{ translates[langUrl].button_booking[lang] }}</BaseButton>
                <BaseButton
                    v-else
                    class="button-fill"
                    style="width: 100%"
                    @click="createBooking(arrival)">{{ translates[langUrl].button_pay[lang] }}</BaseButton>
            </div>
        </template>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { priceFormated, dateFormated } from '@/helpers';
    import { BOOKING_STATUS } from '@/vars';
    import BaseButton from '../BaseButton';

    export default {
        name: 'Arrivals',
        components: {
            BaseButton
        },
        props: {
            tripId: { 
                type: String,
                default: () => null
            },
            arrivals: { 
                type: Array,
                default: () => []
            },
        },
        data: () => ({
            BOOKING_STATUS,
            priceFormated,
            travelersNumber: 1,
            langUrl: 'arrivals'
        }),
        computed: {
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                }
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            if (this.user._id) {
                await store.dispatch('bookings/fetch', {});
            }
        },
        methods: {
            checkArrival(arrival) {
                for(const booking of this.bookings) {
                    if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                        return true;
                    }
                }
                return false;
            },
            async createBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            await store.dispatch('bookings/delete', { id: booking._id });
                        }
                    }
                    store.commit('bookings/CLEAR_ENTITY');
                    store.commit('bookings/SET_ENTITY', {
                        trip: this.tripId,
                        arrival: arrival._id,
                        start: arrival.start,
                        finish: arrival.finish,
                        places: this.travelersNumber,
                        price: arrival.price,
                        discount: arrival.discount,
                        currency: arrival.currency,
                    });
                    const booking = await store.dispatch('bookings/save');
                    this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        for(const booking of this.bookings) {
                            if(booking.trip && booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                                await store.dispatch('bookings/delete', { id: booking._id });
                            }
                        }
                        store.commit('bookings/CLEAR_ENTITY');
                        store.commit('bookings/SET_ENTITY', {
                            trip: this.tripId,
                            arrival: arrival._id,
                            start: arrival.start,
                            finish: arrival.finish,
                            places: this.travelersNumber,
                            price: arrival.price,
                            discount: arrival.discount,
                            currency: arrival.currency,
                        });
                        const booking = await store.dispatch('bookings/save');
                        this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                    });
                }
                
            },
            async deleteBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            await store.dispatch('bookings/delete', { id: booking._id });
                        }
                    }
                    await store.dispatch('bookings/fetch', {});
                    await store.dispatch('trips/get', { id: this.tripId });
                } else {
                    this.authorization()
                }
            },
            async payBooking(arrival) {
                if(this.user._id) {
                    for(const booking of this.bookings) {
                        if(booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                            this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                        }
                    }
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        for(const booking of this.bookings) {
                            if(booking.trip._id === this.tripId && booking.arrival.toString() === arrival._id.toString()) {
                                this.$router.push({ name: 'trip-booking', params: { trip_code: this.trip.code, booking_id: booking._id } });
                            }
                        }
                    });
                }
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
        }
    }
</script>

<style lang="scss">
    .trip__arrivals {
        display: flex;
        flex-direction: column;
        @media all and (min-width: 768px) {
            flex-direction: row;
            // justify-content: space-between;
        }
        &__arrival{
            display: flex;
            flex-direction: column;
            padding: 16px 24px 24px;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-top: 20px;
            @media all and (min-width: 768px) {
                width: 326px;
                margin-right: 32px;
                // &:last-of-type {
                //     margin-right: 0;
                // }
            }
            &__dates {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                padding-bottom: 16px;
                border-bottom: 1px solid #F6F7F9;
                &__day {
                    font-size: 14px;
                    line-height: 140%;
                    color: #9FA5AD;
                }
            }
            &__price {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-top: 24px;
            }
            &__info {
                font-size: 12px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                margin: 12px 0 24px;
            }
        }
    }
</style>