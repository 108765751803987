<template>
    <div v-if="translates && translates[langUrl]">
        <DesktopGallery
            v-if="isDesktop"
            :options="{ rewind: true, type: 'loop', perPage: placesSplideCounter, perMove: 1, gap: '20px' }"
            :items="trip.mainPlaces"
            :title="translates[langUrl].blockName_mainPlaces[lang]"
            :number-of-images="placesSplideCounter"
        />
        <TripGallery
            v-if="isDesktop && !isDesktop"
            :items="trip.mainPlaces"
            :title="translates[langUrl].blockName_mainPlaces[lang]"
            class="large" />
        <yandexMap
            v-if="isDesktop && trip.direction"
            ref="ymap"
            class="detail-main-block__yandexmap"
            :controls="['zoomControl']"
            :coords="centerCoordinates"
            zoom="6"
        >
            <template v-for="(direct, i) in trip.direction">
                <ymapMarker 
                    :key="i"
                    :marker-id="i"
                    marker-type="Placemark"
                    :coords="direct.coordinates"
                />
            </template>
            <ymapMarker
                marker-id="1"
                marker-type="Polyline"
                :coords="polyline"
            />
        </yandexMap>
        <template v-if="trip.type === TRIP_TYPE.TOUR_BLOGGER">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="detail-main-block__block-name">{{ translates[langUrl].blockName_blogger[lang] }}</div>
                <div v-if="trip.type === TRIP_TYPE.TOUR_BLOGGER && trip.blogger && user._id === trip.blogger._id" class="detail-main-block__workshops__edit" @click="profileEdit"></div>
            </div>
            <Organizer :user="blogger" />
        </template>
        <Benefits
            v-if="trip.benefits && trip.benefits[0] && trip.benefits[0].title && trip.benefits[0].title !== ''"
            :title="translates[langUrl].blockName_highlights[lang]"
            :items="trip.benefits"
        />

        <template v-if="trip.type === TRIP_TYPE.TOUR_LEADER">
            <div v-if="isDesktop" class="detail-main-block__block-name">{{ translates[langUrl].blockName_individualFormat[lang] }}</div>
            <IndividualFormat v-if="isDesktop" />
            <div style="width: 100%; display: flex; justify: center">
                <BaseButton
                    v-if="bloggersPage"
                    style="width: 326px; margin: 40px auto 0"
                    @click="participation"
                >{{ translates[langUrl].button_choose[lang] }}</BaseButton>
                <BaseButton
                    v-else
                    style="width: 326px; margin: 40px auto 0"
                    @click="individual"
                >{{ individualText? individualText : `${ translates[langUrl].defaultIndividualText[lang] }` }}</BaseButton>
            </div>
        </template>
        
        <template v-if="trip.programs && trip.programs.length > 1">
            <div ref="program" class="detail-main-block__block-name">{{ translates[langUrl].blockName_tourProgram[lang] }}</div>
            <TripProgram :items="trip.programs" :isDesktop="isDesktop" />
        </template>

        <template v-if="trip.workshops && trip.workshops[0] && trip.workshops[0].title && trip.workshops[0].title !== ''">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="detail-main-block__block-name">{{ translates[langUrl].workshops_title[lang] }}</div>
                <div
                    class="detail-main-block__workshops__edit"
                    @click="openPopupWorkshop"
                    v-if="trip.type === TRIP_TYPE.TOUR_BLOGGER && trip.blogger && user._id === trip.blogger._id && bloggersTrip"
                ></div>
            </div>
            <div class="detail-main-block__workshops">
                <template v-for="(workshop, i) in trip.workshops">
                    <div :key="'workshop' + i" class="detail-main-block__workshops__item" v-if="workshop.title">
                        <div class="detail-main-block__workshops__item__title">
                            {{ workshop.title }}
                        </div>
                        <div class="detail-main-block__workshops__item__description">
                            {{ workshop.description }}
                        </div>
                    </div>
                </template>
            </div>
        </template>

        <template>
            <div class="detail-main-block__presentation">
                <div class="detail-main-block__presentation__text">
                    <div class="detail-main-block__presentation__title">{{ translates[langUrl].presentation_title[lang] }}</div>
                    <div class="detail-main-block__presentation__description">{{ translates[langUrl].presentation_description[lang] }}</div>
                </div>
                <BaseButton
                    v-if="bloggersPage"
                    class="button-fill detail-main-block__presentation__button"
                    @click="participation"
                >{{ translates[langUrl].button_participation[lang] }}
                </BaseButton>
                <BaseButton
                    v-else
                    class="button-fill detail-main-block__presentation__button"
                    @click="downloadProgram()"
                >{{ translates[langUrl].button_downloadPresentation[lang] }}<img src="../../../assets/DownloadWhite.svg" style="width: 20px; heigth: 20px; margin-left: 5px" />
                </BaseButton>
            </div>
            <div v-if="!bloggersPage" ref="organizer" class="detail-main-block__block-name">{{ translates[langUrl].blockName_expert[lang] }}</div>
            <Organizer 
                v-if="!bloggersPage" 
                :user="trip.organizer && trip.organizer.firstname ? trip.organizer : trip.user" />
        </template>

        <h3 v-if="trip.included && trip.included.length > 0 && trip.included[0].title !== '' && trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== ''" 
            class="detail-main-block__block-name"
            >{{ translates[langUrl].blockName_advantages[lang] }}</h3>
        <TripAdvantages 
            v-if="trip.included && trip.included.length > 0 && trip.included[0].title !== ''" 
            :items="trip.included" 
            :openAllItems="true"
            />
        <TripAdvantages
            v-if="trip.notIncluded && trip.notIncluded.length > 0 && trip.notIncluded[0].title !== ''"
            class="notIcluded"
            :items="trip.notIncluded" />
        <DesktopGallery
            v-if="isDesktop"
            ref="accommodation"
            :options="{ rewind: true, type: 'loop', fixedWidth : '650px', fixedHeight: '350px', perMove: 1, gap: '20px' }"
            :items="trip.accommodations"
            :title="translates[langUrl].blockName_accommodations[lang]"
            number-of-images="1"
            :description="trip.accommodationsDescription"
            description-lenght="250"
            class="large" />
        <TripGallery
            v-if="!isDesktop"
            :items="trip.accommodations"
            :title="translates[langUrl].blockName_accommodations[lang]"
            :description="trip.accommodationsDescription"
            description-lenght="80"
            class="raiting" />
        <DesktopGallery
            v-if="isDesktop"
            :options="{ rewind: true, type: 'loop', fixedWidth : '650px', fixedHeight: '350px', perMove: 1, gap: '20px' }" 
            :items="trip.activities"
            :title="translates[langUrl].blockName_activities[lang]"
            number-of-images="1"
            :description="trip.activitiesDescription"
            description-lenght="200"
            class="large" 
        />
        <TripGallery
            v-if="!isDesktop"
            :items="trip.activities"
            :title="translates[langUrl].blockName_activities[lang]"
            :description="trip.activitiesDescription"
            description-lenght="80"
        />
        <DesktopGallery
            v-if="isDesktop"
            :options="{ rewind: true, type: 'loop', fixedWidth : '650px', fixedHeight: '350px', perMove: 1, gap: '20px' }"
            :items="trip.transports"
            :title="translates[langUrl].blockName_transports[lang]"
            number-of-images="1"
            :description="trip.transportsDescription"
            description-lenght="200"
            class="large"
            :transports="trip.transportsTypes"
        />
        <TripGallery
            v-if="!isDesktop"
            :items="trip.transports"
            :title="translates[langUrl].blockName_transports[lang]"
            :description="trip.transportsDescription"
            description-lenght="80"
        />
        <v-dialog
            v-model="popupParticipation"
            max-width="600px"
        >
            <PopupParticipation
                @close="closePopupParticipation"
            />
        </v-dialog>
        <v-dialog
            v-model="popupDownload"
            max-width="600px"
        >
            <PopupDownload
                :code="trip.code"
                @close="popupDownload = false"
            />
        </v-dialog>
        <v-dialog
            v-model="PopupNoPresentation"
            max-width="600px"
        >
            <PopupNoPresentation 
                :name="user.firstname"
                @close="closePopupNoPresentation"
                @writeToExpert="writeToExpert"
            />
        </v-dialog>
    </div>
</template>

<script>
    import BaseButton from '../BaseButton.vue';
    import TripProgram from './Program.vue';
    import TripAdvantages from './Advantages.vue';
    import TripGallery from './Gallery.vue';
    import DesktopGallery from './DesktopGallery.vue';
    import Organizer from './Organizer.vue';
    import IndividualFormat from './IndividualFormat.vue';
    import Benefits from './Benefits.vue';
    import PopupConsultation from '../PopupConsultation.vue';
    import PopupDownload from '../PopupDownload.vue';
    import PopupNoPresentation from '../PopupNoPresentation.vue';
    import PopupParticipation from '../PopupParticipation.vue';

    import store from '@/store';
    import { mapState } from 'vuex';
    import { TRIP_TYPE, FEEDBACK_TYPE } from '@/vars';
    import { imageSrc, SplideSlideCounter, isMobile } from '@/helpers';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    

    export default {
        name: 'DetailMainBlock',
        components: {
            BaseButton,
            TripProgram,
            Organizer,
            IndividualFormat,
            DesktopGallery,
            TripAdvantages,
            Benefits,
            yandexMap,
            ymapMarker,
            TripGallery,
            PopupConsultation,
            PopupDownload,
            PopupNoPresentation,
            PopupParticipation
        },
        data: () => ({
            TRIP_TYPE,
            FEEDBACK_TYPE,
            imageSrc,
            isDesktop: false,
            hide: true,
            lastMove: null,
            placesSplideCounter: 1,
            popupConsultation: false,
            popupParticipation: false,
            popupDownload: false,
            PopupNoPresentation: false,
            feedback: {
                trip: null,
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
            },
            bloggersPage: false,
            individualText: '',
            langUrl: '/trip/name',
            tripPDF: ''
        }),
        props: {
            bloggersTrip: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities,
                trip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            blogger() {
                if(this.trip.type === TRIP_TYPE.TOUR_BLOGGER && this.trip.bloggerTrips && this.trip.bloggerTrips[0]) {
                    return this.trip.blogger;
                }
                return {};
            },
            photos() {
                return this.trip.photos? this.trip.photos.map(item => item.picture) : [] || []
            },
            programsFormatted() {
                return this.trip && this.trip.programs ? this.trip.programs.map((item, index) => {
                    if(this.lang === 'ru') {
                        item.title = `День ${ index + 1 }. ${ item.title }`;
                        return item;
                    } else {
                        item.title = `Day ${ index + 1 }. ${ item.title }`;
                        return item;
                    }
                    
                }) : []
            },
            options () {
                return {
                    offset: 100
                }
            },
            polyline() {
                return this.trip.direction ? this.trip.direction.map(item => item.coordinates) : [];
            },
            centerCoordinates() {
                return this.trip.direction && this.trip.direction.length ? [
                    this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                        return sum + +item.coordinates[0]
                    }, 0) / this.trip.direction.length : 55.749451,
                    this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                        return sum + +item.coordinates[1]
                    }, 0) / this.trip.direction.length : 37.542824,
                ] : [];
            },
        },
        created () {
            this.isDesktop = !isMobile();
            this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
        },
        mounted() {
        },
        methods: {
            async writeToExpert() {
                this.$emit('writeToExpert')
            },
            async downloadProgram() {
                if(this.user._id) {
                    this.sendFeedback(FEEDBACK_TYPE.DOWNLOAD_PROGRAM);
                    this.popupDownload = true;
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(FEEDBACK_TYPE.DOWNLOAD_PROGRAM);
                        this.popupDownload = true;
                    });
                }
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async sendFeedback(type) {
                this.feedback = {
                    trip: this.trip._id,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    email: this.user.email,
                    type: type,
                    link: document.location.href
                };
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            async consultation() {
                if (this.user._id) {
                    this.sendFeedback(FEEDBACK_TYPE.CONSULTATION);
                    this.openPopupConsultation();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(FEEDBACK_TYPE.CONSULTATION);
                        this.openPopupConsultation();
                    });
                }
            },
            async participation() {
                if (this.user._id) {
                    this.sendFeedback(FEEDBACK_TYPE.PARTICIPATION);
                    this.openPopupParticipation();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(FEEDBACK_TYPE.PARTICIPATION);
                        this.openPopupParticipation();
                    });
                }
            },
            async individual() {
                if (this.user._id) {
                    this.sendFeedback(FEEDBACK_TYPE.INDIVIDUAL_TRIP);
                    this.individualText = `${ this.translates[this.langUrl].requestIndividualAccepted[this.lang]}`
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.sendFeedback(FEEDBACK_TYPE.INDIVIDUAL_TRIP);
                        this.individualText = `${ this.translates[this.langUrl].requestIndividualAccepted[this.lang]}`
                    });
                }
            },
            openPopupConsultation() {
                this.popupConsultation = true;
            },
            closePopupConsultation() {
                this.popupConsultation = false;
            },
            openPopupParticipation() {
                this.popupParticipation = true;
            },
            closePopupParticipation() {
                this.popupParticipation = false;
            },
            openPopupNoPresentation() {
                this.PopupNoPresentation = true;
            },
            closePopupNoPresentation() {
                this.PopupNoPresentation = false;
            },
            openPopupWorkshop() {
                this.$emit('openWorkshopsPopup')
            },
            profileEdit() {
                const href = `${ process.env.VUE_APP_CURRENT_URL }/user/profile/edit`
                window.open(href, '_blank');
            }
        }
    }
</script>

<style lang="scss">
.detail-main-block {
    &__yandexmap {
        width: 100%;
        height: 400px;
        margin-top: 38px;
        border-radius: 12px;
        overflow: hidden;
    }
    &__presentation {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 112px;
        padding: 30px 30px 30px 25px;
        background: #F6F7F9;
        border-radius: 12px;
        justify-content: space-between;
        margin-top: 70px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            min-height: 220px;
            background: #F6F7F9;
            border-radius: 12px;
            padding: 16px 24px;
            margin: 40px 0;
        }
        &__text {
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
        &__title {
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            @media all and (max-width: 768px) {
                font-weight: 600;
                font-size: 22px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 12px;
            }
        }
        &__description {
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            @media all and (max-width: 768px) {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 24px;
            }
        }
        &__button {
            width: 254px;
            height: 52px;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
        margin-top: 70px;
    }
    &__workshops {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &__item {
            font-family: 'Inter';
            font-style: normal;
            margin-bottom: 20px;
            &__title {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #273155;
                margin-bottom: 12px;
            }
            &__description {
                font-weight: 400;
                font-size: 16px;
                line-height: 180%;
                color: #3A4256;

            }
        }
        &__edit {
            margin-top: 80px;
            min-width: 25px;
            height: 25px;
            background: url(../../../assets/Edit.svg);
            cursor: pointer;
        }
    }
}
</style>
