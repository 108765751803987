<template>
    <div class="trip-chat" v-if="trip && expert && currentUser._id && currentUser._id !== expert._id && translates && translates[langUrl]">
        <beautiful-chat
            :participants="participants"
            :on-message-was-sent="onMessageWasSent"
            :message-list="messagesFormatted"
            :new-messages-count="unreadCount"
            :is-open="isChatOpen"
            :close="closeChat"
            :open="openChat"
            :show-emoji="false"
            :show-file="false"
            :show-edition="true"
            :show-deletion="true"
            :show-typing-indicator="''"
            :show-launcher="true"
            :show-close-button="true"
            :colors="colors"
            :always-scroll-to-bottom="true"
            :disable-user-list-toggle="true"
            :message-styling="false"
            :placeholder="translates[langUrl].placeholder[lang]">
            <template #header>
                <div v-if="trip && expert" class="trip-chat__header">
                    <div class="trip-chat__header__avatar" :style="`background-image: url(${imageSrc(expert.avatar)});`"></div>
                    <div class="trip-chat__header__name">
                        {{ expert.firstname }} {{ expert.lastname ? expert.lastname[0] + '.' : '' }}
                        <span>{{ translates[langUrl].organizer[lang] }}</span>
                    </div>
                </div>
            </template>
            <template #user-avatar>
                <div></div>
            </template>
            <template #text-message-body="{ message }">
                <div class="trip-chat__message-container">
                    <div class="trip-chat__message-card trip-chat__message-current">
                        <div class="trip-chat__format-message-wrapper">
                            <div class="">
                                <div class="trip-chat__format-container">
                                    <span class=""><span>{{ message.data.text }}</span></span>
                                </div>
                            </div>
                        </div>
                        <div class="trip-chat__text-timestamp">
                            <span>{{ message.data.timestamp }}</span>
                            <span v-if="message.author === 'me'">
                                <svg
                                    v-if="message.data.distributed && message.data.seen"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    class="trip-chat__icon-check__seen">
                                    <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
                                </svg>
                                <svg
                                    v-else-if="message.data.distributed"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    class="trip-chat__icon-check__seen">
                                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                                <svg
                                    v-else
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    class="trip-chat__icon-check">
                                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>

                <small v-if="message.meta" style="background:red">
                    {{ message.meta }}
                </small>
                {{ message.text }}
            </template>
        </beautiful-chat>
    </div>
</template>

<script>
    import moment from '@/plugins/moment'; 
    import store from '@/store';
    import { mapState } from 'vuex';

    import { isMobile, imageSrc } from '@/helpers';
    import { CHAT_MESSAGE_STATUS, TRIP_TYPE } from '../../../vars';

    export default {
        name: 'TripChat',
        props: {
            trip: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                TRIP_TYPE,
                imageSrc,
                isDesktop: false,
                activeChat: {},
                updateTimerId: null,
                titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
                isChatOpen: false,
                colors: {
                    header: {
                        bg: '#de155e',
                        text: '#ffffff'
                    },
                    launcher: {
                        bg: '#de155e'
                    },
                    messageList: {
                        bg: '#ffffff'
                    },
                    sentMessage: {
                        bg: '#F6F6F6',
                        text: '#273155'
                    },
                    receivedMessage: {
                        bg: '#F6F6F6',
                        text: '#273155'
                    },
                    userInput: {
                        bg: '#f4f7f9',
                        text: '#565867'
                    }
                },
                langUrl: 'chat'
            }
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', {
                currentUser: state => state.user
            }),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('chats', ['chats', 'messages']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            expert() {
                if(this.trip.type === TRIP_TYPE.TOUR_BLOGGER && this.trip.bloggerTrips && this.trip.bloggerTrips[0]) {
                    return this.trip.bloggerTrips[0].blogger;
                }
                return this.trip.user;
            },
            messagesFormatted() {
                const messages = this.messages ? this.messages.map(item => {
                    return { 
                        type: 'text', 
                        author: item.user_id === this.currentUser._id ? 'me' : item.user_id, 
                        data: { 
                            timestamp: moment(item.createdAt).format('DD.MM HH:mm'),
                            text: item.text, 
                            distributed: [CHAT_MESSAGE_STATUS.DELIVERED, CHAT_MESSAGE_STATUS.READED].indexOf(item.status) >= 0,
                            seen: item.status === CHAT_MESSAGE_STATUS.READED,
                        } 
                    }
                }) : [];
                return [{ 
                        type: 'text', 
                        author: this.trip && this.expert ? this.expert._id : '',
                        data: { 
                            timestamp: moment(new Date()).format('DD.MM HH:mm'),
                            text: `${ this.translates[this.langUrl].welcomeMessage_part_1[this.lang] } ${ this.currentUser ? this.currentUser.firstname : '' }! ${ this.translates[this.langUrl].welcomeMessage_part_2[this.lang] }`, 
                        } 
                    },
                    ...messages];
            },
            participants() {
                return this.activeChat.id ? this.activeChat.users.filter(item => {
                        return item._id !== this.currentUser._id;   
                    }).map(item => {
                        return {
                            id: item._id,
                            name: item.firstname + (item.lastname ? item.lastname[0] + '.' : ''),
                            imageUrl: item.avatar ? this.imageSrc(item.avatar) : ''
                        }
                    }) : [
                        {
                            id: this.trip && this.expert ? this.expert._id : '',
                            name: this.trip && this.expert ? this.expert.firstname + (this.expert.lastname ? this.expert.lastname[0] + '.' : '') : '',
                            imageUrl: this.trip && this.expert ? this.expert.avatar ? this.imageSrc(this.expert.avatar) : '' : ''
                        }
                    ];
            },
            unreadCount() {
                if(this.activeChat.id) {
                    return this.activeChat.messages.filter(message => (message.user_id !== this.currentUser._id && message.status !== CHAT_MESSAGE_STATUS.READED)).length;
                }
                return 0;
            }
        },
        async mounted() {
            const this_ = this;
            await store.commit('chats/CLEAR_ENTITY');
            if (this.updateTimerId) {
                clearInterval(this.updateTimerId);
            }
            if (!this_.currentUser._id) {
                await store.dispatch('auth/fetch');
            }
            if (this_.currentUser._id) {
                await this_.getData();
                this_.updateTimerId = setInterval(async () => {
                    await this_.getData();
                }, 10000);
            }
            this_.$root.$on('tripChatOpen', async () => {
                this_.getData();
                this_.isChatOpen = true;
            });
        },
        beforeDestroy () {
            clearInterval(this.updateTimerId);
        },
        created() {
            window.addEventListener('resize', this.onResize);
            window.visualViewport.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
            window.visualViewport.removeEventListener('resize', this.onResize);
        },
        methods: {
            async getData() {
                await store.dispatch('chats/fetch', {});
                for(const chat of this.chats) {
                    if(chat.trip._id === this.trip._id) {
                        this.activeChat = chat;
                    }
                }
                if(this.activeChat.id && this.isChatOpen) {
                    await store.dispatch('chats/fetchMessages', { chat_id: this.activeChat.id });
                }
            },
            async onMessageWasSent (message) {
                if (message.data.text.length > 0) {
                    if(!this.activeChat.id) {
                        this.activeChat = await store.dispatch('chats/create', { trip_id: this.trip._id, user_1: this.currentUser._id, user_2: this.expert._id });
                    }
                    await store.dispatch('chats/sendMessage', { chat_id: this.activeChat.id, user_id: this.currentUser._id, content: message.data.text });
                    await this.getData();
                }
            },
            async openChat () {
                this.isChatOpen = true;
                if(this.activeChat.id) {
                    await store.dispatch('chats/fetchMessages', { chat_id: this.activeChat.id });
                }
            },
            closeChat () {
                this.isChatOpen = false;
            },
            onResize() {
                this.isDesktop = !isMobile();
                document.documentElement.style.setProperty('--app--chat-height', `${ window.visualViewport ? window.visualViewport.height : window.innerHeight }px`);
            },
        }
    }
</script>

<style lang="scss">
.trip-chat {
    position: relative; z-index: 100;

    .sc-launcher {
        @media all and (max-width: 1024px) {
            display: none;
        }
    }
    .sc-chat-window {
        @media all and (max-width: 450px) {
            height: 100vh;
            height: var(--app--chat-height);
            max-height: 100vh;
            max-height: var(--app--chat-height);
        }
    }
    .sc-message-list {
        padding: 16px;
    }
    .sc-message {
        width: 100%;
    }
    .sc-message--text {
        padding: 2px 10px;
        min-width: 100px;
    }
    .sc-user-input--text {
        width: calc(100% - 50px);
    }
    .sc-user-input--buttons {
        width: 50px;
        right: 0;

        .sc-user-input--button,
        .sc-user-input--button-icon-wrapper {
            width: 50px;
            height: 100%;
        }
        .sc-user-input--button:first-child {
            display: none;
        }
    }
    .sc-user-input--button-icon-wrapper > svg {
        fill: #de155e !important;
        color: #de155e !important;
    }
    .sc-new-messsages-count {
        color: #de155e;
        border: 1px solid #de155e;
        background: #ffffff;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__avatar {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            margin-right: 10px;
        }
        &__name {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            > span {
                display: block;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
        }
    }

    &__text-timestamp {
        font-size: 10px;
        color: #828c94;
        text-align: right;

        svg {
            height: 14px;
            width: 14px;
            vertical-align: middle;
            margin: -3px -3px 0 3px;
        }
    }
    &__icon-check {
        fill: #828c94;
        &__seen {
            fill: #de155e;
        }
    }
}
</style>