<template>
    <div class="benefits">
        <div class="benefits__title">{{ title }}</div>
        <div class="benefits__container">
            <template v-for="(item, index) in items">
                <div :key=" 'benefit-' + index" class="benefits__benefit">
                    <img src="../../../assets/CheckMarkPink.svg" />
                    <div v-if="item && item.title" class="benefits__benefit__value"> {{ item.title }} </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Benefits',
        props: {
            title: {
                type: String,
                default: ''
            },
            items: {
                type: Array,
                default: () => []
            }
        }
    }

</script>

<style lang="scss">
.benefits {
    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #3A4256;
        display: flex;
        align-items: center;
        margin-top: 40px;
        @media all and (min-width: 768px) {
            font-size: 28px;
            color: #273155;
            margin-top: 70px;
        }
    }
    &__container {
        margin-top: 28px;
    }
    &__benefit {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        @media all and (min-width: 768px) {
            margin-bottom: 25px;
        }
        &__value {
            margin-left: 10px;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
            @media all and (min-width: 768px) {
                font-size: 16px;
                line-height: 150%;
                margin-left: 15px;
            }
        }
    }
}
</style>
