<template>
    <div class="trip-gallery">
        <div v-if="title && title !== ''" class="trip-gallery__title">{{ title }}</div>
        <Description v-if="description && description !== ''" :item="description" :descriptionLenght='descriptionLenght' />
        <div v-if="items.length > 0 && items[0].picture !== ''" class="trip-gallery__gallery">
            <template v-for="(item, index) in items"> 
                <div :key=" 'picture-' + index">
                    <div 
                        v-if="item.pictures"
                        :style="`background-image: url(${ imageSrc(item.pictures[item.mainPictureIndex]) })`"
                        class="trip-gallery__picture">
                    </div>
                    <div
                        v-if="item.picture"
                        :style="`background-image: url(${ imageSrc(item.picture) })`"
                        class="trip-gallery__picture">
                    </div>
                    <div class="trip-gallery__position">
                        <div class="trip-gallery__text"> {{ item.title }} </div>
                        <!-- <div class="trip-gallery__raiting">Рейтинг на Booking.com<span class="trip-gallery__raiting__value">9.5</span></div> -->
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import Description from '../../../components/common/trip/Description.vue'
    export default {
        name: 'TripGallery',
        components: {
            Description,
        },
        props: {
            items: { 
                type: Array, 
                default: () => []
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            descriptionLenght: {
                type: [Number, String],
                default: 0
            }
        },
        data: () => ({
            imageSrc,
            raiting: false
        }),
    }
</script>

<style lang="scss">
.trip-gallery {
    // margin-left: 20px;
    @media all and (min-width: 768px) {
        margin-left: 0;
    }
    &__gallery{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-top: 16px;
    padding-bottom: 7px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__position {
            display: flex;
            flex-direction: column;
            @media all and (min-width: 768px) {
                flex-direction: row;
            }
        }
    &__title{
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #3A4256;
        display: flex;
        align-items: center;
        margin-top: 40px;
        @media all and (min-width: 768px) {
            font-size: 28px;
            line-height: 130%;
            color: #273155;
        }
    }
    &__picture {
        position: relative;
        background-size: cover;
        background-position: center;
        min-width: 257px;
        height: 180px;
        border-radius: 8px;
        margin-right: 16px;
        @media all and (min-width: 768px) {
            min-width: 240px;
            height: 200px;
            margin-right: 22px;
        }
    }
    &__text {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #3A4256;
        margin-top: 8px;
        @media all and (min-width: 768px) {
            font-weight: normal;
            line-height: 180%;
            margin-top: 12px;
            font-size: 20px;
            line-height: 24px;
            color: #3A4256;
        }
    }
    &__raiting {
        display: none;
        margin-top: 4px;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
        align-items: center;
        @media all and (min-width: 768px) {
            margin: 12px 0 0 36px;
            font-size: 20px;
            line-height: 24px;
            color: #3A4256;
        }
        &__value {
            margin-left: 5px;
        }
    }
    &.large {
        .trip-gallery__picture {
            width: 270px;
            height: 210px;
        }

    }
    &.raiting {
        .trip-gallery__raiting {
            display: flex;
        }
    }
}
</style>
