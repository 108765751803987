<template> 
    <div v-if="item && item !== 0 && translates && translates[langUrl]" class="description">
        <div class="description__text" :class="{ description_hide: hide }">{{ item }}</div>
        <div
            v-if="item && item !== '' && item.length > descriptionLenght"
            class="description__button"
            @click="hide = !hide">{{ hide ? `${translates[langUrl].button_show[lang]}` : `${translates[langUrl].button_hide[lang]}` }}</div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    
    export default {
        name: 'Description',
        props: {
            item: { 
                type: String, 
                default: ''
            },
            descriptionLenght: {
                type: [Number, String],
                default: 0
            }
        },
        data: () => ({
            hide: true,
            langUrl: 'description'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        }
    }
</script>

<style lang="scss">
.description {
        &__text{
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
        margin-top: 16px;
            &.description_hide {
                max-height: 45px;
                overflow: hidden;
            }
        }
        &__button {
            color: #DA0A63;
            margin-top: 5px;
        }
        }
</style>