<template>
    <div v-if="items.length !== 0 && items[0].picture && items[0].picture !== '' || items.length !== 0 && items[0].pictures && items[0].pictures[0]" class="desktop-trip-gallery">
        <div class="desktop-trip-gallery__title">{{ title }}</div>
        <template v-for="(accommodationType, index) in formattedAccommodationTypes">
            <div :key="index" class="desktop-trip-gallery__types">
                <img :src="`${ imageSrc(accommodationType.picture) }`" class="desktop-trip-gallery__types__picture" />
                <div class="desktop-trip-gallery__types__text"> {{ accommodationType.title }} </div>
            </div>
        </template>
        <template v-for="(transportTypes, index) in formattedTransportTypes">
            <div :key="index" class="desktop-trip-gallery__types">
                <img :src="`${ imageSrc(transportTypes.picture) }`" class="desktop-trip-gallery__types__picture" />
                <div class="desktop-trip-gallery__types__text"> {{ transportTypes.title }} </div>
            </div>
        </template>
        <Description
            v-if="description && description !== ''"
            :item="description"
            :description-lenght="descriptionLenght" />
        <div v-if="items.length !== 0 && items.length <= +numberOfImages" class="desktop-trip-gallery__gallery">
            <template v-for="(item, index) in items"> 
                <div :key=" 'picture-' + index" class="desktop-trip-gallery__picture-container">
                    <div
                        v-if="item.picture"
                        :style="`background-image: url(${ imageSrc(item.picture) })`"
                        class="desktop-trip-gallery__picture">
                    </div>
                    <div v-if="item.picture" class="desktop-trip-gallery__position">
                        <div class="desktop-trip-gallery__text"> {{ item.description }} </div>
                        <!-- <div class="desktop-trip-gallery__raiting">Рейтинг на Booking.com<span class="desktop-trip-gallery__raiting__value">9.5</span></div> -->
                    </div>
                    <div
                        v-if="item.pictures"
                        :style="`background-image: url(${ imageSrc(item.pictures[item.mainPictureIndex]) })`"
                        class="desktop-trip-gallery__picture"></div>
                    <div class="desktop-trip-gallery__position">
                        <div class="desktop-trip-gallery__text"> {{ item.title }}</div>
                        <!-- <div class="desktop-trip-gallery__raiting">Рейтинг на Booking.com<span class="desktop-trip-gallery__raiting__value">9.5</span></div> -->
                    </div>
                </div>
            </template>
        </div>
        <Splide
            v-if="items.length !== 0 && items[0].picture !== '' && items.length > numberOfImages"
            :options="options"
            class="desktop-trip-gallery__gallery">
            <template v-for="(item, index) in items"> 
                <SplideSlide :key=" 'picture-' + index" style="height: auto">
                    <div
                        v-if="item.picture"
                        :style="`background-image: url(${ imageSrc(item.picture) })`"
                        class="desktop-trip-gallery__picture"></div>
                    <div v-if="item.picture && item.description" class="desktop-trip-gallery__position">
                        <div class="desktop-trip-gallery__text"> {{ item.description }}</div>
                        <!-- <div class="desktop-trip-gallery__raiting">Рейтинг на Booking.com<span class="desktop-trip-gallery__raiting__value">9.5</span></div> -->
                    </div>
                    <div
                        v-if="item.pictures"
                        :style="`background-image: url(${ imageSrc(item.mainPictureIndex ? item.pictures[item.mainPictureIndex] : item.pictures[0]) })`"
                        class="desktop-trip-gallery__picture"></div>
                    <div class="desktop-trip-gallery__position">
                        <div class="desktop-trip-gallery__text"> {{ item.title }}</div>
                    </div>
                </SplideSlide>
            </template>
        </Splide>
    </div>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import Description from '../../../components/common/trip/Description'
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { mapState } from 'vuex';

    export default {
        name: 'DesktopGallery',
        components: {
            Description,
            Splide,
            SplideSlide,
        },
        props: {
            items: { 
                type: Array, 
                default: () => []
            },
            title: {
                type: String,
                default: ''
            },
            description: {
                type: String,
                default: ''
            },
            descriptionLenght: {
                type: [Number, String],
                default: 0
            },
            options: {
                type: Object,
                default: () => {}
            },
            numberOfImages: {
                type: [Number, String],
                default: 0
            },
            types: {
                type: Array,
                default: () => []
            },
            transports: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            imageSrc,
            raiting: false
        }),
        computed: {
            ...mapState('dictionaries', ['transportTypes', 'accommodationsTypes']),
            formattedAccommodationTypes() {
                return this.accommodationsTypes ? this.accommodationsTypes.filter(item => (this.types.indexOf(item.code) >= 0)) : [];
            },
            formattedTransportTypes() {
                return this.transportTypes ? this.transportTypes.filter(item => (this.transports.indexOf(item.code) >= 0)) : [];
            }
        },
        // methods: {
        // }
    }
</script>

<style lang="scss">
.desktop-trip-gallery {
    margin-left: 20px;
    @media all and (min-width: 768px) {
        margin-left: 0;
    }
    &__types{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 15px;
        margin-top: 15px;
        border-bottom: 1px solid #EBEBEB;
        &__picture {
            width: 65px;
            height: 65px;
            padding: 15px;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-right: 20px;
        }
        &__text {
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
    }
    &__gallery {
        position: relative;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        margin-top: 16px;
        padding-bottom: 7px;

        &::-webkit-scrollbar {
            display: none;
        }

        .splide__pagination__page {
            display: none;
        }
        .splide__arrow {
            position: absolute;
            width: 40px;
            height: 40px;
            opacity: 1;
            padding: 12px;
            top: 100px;
            background: #fff;
            // &:hover {
            //     box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            // }
        }
        .splide__arrow--prev {
            content: url(../../../assets/BeforeArrow.svg);
            opacity: 1;
            left: 0;
            &:hover {
                box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
            }
        }
        .splide__arrow--next {
            content: url(../../../assets/NextArrow.svg);
            right: 0;
        }
    }
    &__position {
        display: flex;
        flex-direction: row;
    }
    &__title{
        font-weight: 600;
        letter-spacing: -0.02em;
        display: flex;
        align-items: center;
        margin-top: 40px;
        font-size: 28px;
        line-height: 130%;
        color: #273155;
    }
    &__picture-container {
        min-width: 260px;
        margin-right: 20px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    &__picture {
        position: relative;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        width: 100%;
        height: 200px;
    }
    &__text {
        font-weight: normal;
        font-weight: 500;
        line-height: 180%;
        margin-top: 12px;
        font-size: 20px;
        line-height: 24px;
        color: #3A4256;
    }
    &__raiting {
        display: none;
        letter-spacing: -0.02em;
        align-items: center;
        margin: 12px 0 0 36px;
        font-size: 20px;
        line-height: 24px;
        color: #3A4256;
        &__value {
            margin-left: 5px;
        }
    }
    &.large {
        .desktop-trip-gallery__picture {
            width: 650px;
            height: 350px;
        }
        .desktop-trip-gallery__gallery {
            .splide__arrow {
                top: 175px;
            }
        }
    }
    &.raiting {
        .desktop-trip-gallery__raiting {
            display: flex;
        }
    }
}
</style>
