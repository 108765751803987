<template>
    <div class="reviewForm" v-if="translates && translates[langUrl]">
        <div v-if="!formReverse">
            <h1 class="reviewForm__page-name">{{ translates[langUrl].pageTitle_newReview[lang] }}</h1>
            <div class="reviewForm__close" @click="close"></div>
            <ValidationObserver ref="validator_1" slim>
                <div class="reviewForm__raiting-line main-raiting">
                    <div class="reviewForm__raiting__key">{{ translates[langUrl].rateTheTour[lang] }}</div>
                    <v-rating
                        v-model="review.rating.overall"
                        background-color="grey"
                        color="warning"
                        hover
                        length="5"
                        size="30"
                    />
                </div>
                <!-- <p>Рэйтинг</p>
                <v-row>
                    <v-col>
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <v-select
                                v-model="review.rating.overall"
                                label="Общий"
                                :items="[0,1,2,3,4,5]"
                                outlined
                                :error-messages="errors[0]"
                            />
                        </ValidationProvider>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.route"
                            :label="translates[langUrl].rating_rout[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.food"
                            :label="translates[langUrl].rating_food[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.accommodation"
                            Жlabel="translates[langUrl].rating_accommodation[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="review.rating.transport"
                            :label="translates[langUrl].rating_transport[lang]"
                            :items="[0,1,2,3,4,5]"
                            outlined
                        />
                    </v-col>
                </v-row> -->
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <v-textarea
                        v-model="review.text"
                        :label="translates[langUrl].fieldName_review[lang]"
                        auto-grow
                        outlined
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                <div class="reviewForm__additional-raiting__container">
                    <div class="reviewForm__additional-raiting">
                        <div class="reviewForm__additional-raiting__raiting__key">
                            {{ translates[langUrl].rating_transport[lang] }}
                        </div>
                        <div class="reviewForm__raiting-line other">
                            <v-rating
                                v-model="review.rating.transport"
                                background-color="grey"
                                color="warning"
                                hover
                                length="5"
                                size="30"
                            />
                            <!-- <div class="review__additional-raiting__delete">{{ translates[langUrl].button_delete[lang] }}</div> -->
                        </div>
                    </div>
                    <div class="reviewForm__additional-raiting">
                        <div class="reviewForm__additional-raiting__raiting__key">
                            {{ translates[langUrl].rating_rout[lang] }}
                        </div>
                        <div class="reviewForm__raiting-line other">
                            <v-rating
                                v-model="review.rating.route"
                                background-color="grey"
                                color="warning"
                                hover
                                length="5"
                                size="30"
                            />
                            <!-- <div class="review__additional-raiting__delete">{{ translates[langUrl].button_delete[lang] }}</div> -->
                        </div>
                    </div>
                    <div class="reviewForm__additional-raiting">
                        <div class="reviewForm__additional-raiting__raiting__key">
                            {{ translates[langUrl].rating_food[lang] }}
                        </div>
                        <div class="reviewForm__raiting-line other">
                            <v-rating
                                v-model="review.rating.food"
                                background-color="grey"
                                color="warning"
                                hover
                                length="5"
                                size="30"
                            />
                            <!-- <div class="reviewForm__additional-raiting__delete">{{ translates[langUrl].button_delete[lang] }}</div> -->
                        </div>
                    </div>
                </div>
                <div class="reviewForm__input-container">
                    <div class="img-container">
                        <template v-for="(picture, i) in review.pictures">
                            <div
                                :key="'picture' + '-' + i"
                                class="img_slot"
                            >
                                <div :style="`background-image: url(${ imageSrc(picture) })` " class="img_slot__picture">
                                    <div 
                                        class="img_slot__main-picture img_slot__main-picture__button" 
                                        @click="removePicture(i)"
                                    >{{ translates[langUrl].button_delete[lang] }}</div>
                                </div>
                            </div>
                        </template>
                        <ImgLoader
                            :text="translates[langUrl].button_addPicture[lang]"
                            @change="addPicture($event)"
                        />
                    </div>
                </div>
                <BaseButton
                    class="button-fill reviewForm__save"
                    @click="save()">{{ translates[langUrl].button_save[lang] }}</BaseButton>
                <div class="reviewForm__personal-info">
                    {{ translates[langUrl].userAgreement[lang] }}
                </div>
            </ValidationObserver>
        </div>
        <div v-if="formReverse">
            {{ translates[langUrl].thanks[lang] }}
            <div class="reviewForm__close" @click="close"></div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import moment from '@/plugins/moment';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '@/helpers';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseTextArea from '../../components/common/BaseTextArea.vue';
    import ImgLoader from '../../components/common/ImgLoader.vue';

    export default {
        name: 'ReviewForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextArea,
            ImgLoader
        },
        data: () => ({
            review: {
                rating: {
                    overall: 0,
                    route: 0,
                    food: 0,
                    accommodation: 0,
                    transport: 0
                },
                pictures: []
            },
            imageSrc,
            formReverse: false,
            langUrl: 'reviewForm'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('trips/getByCode', { code: this.$route.params.code });
        },
        methods: {
            async save() {
                store.commit('reviews/SET_ENTITY', {
                    ...this.review,
                    ...{
                        date: moment().format(),
                        user: this.user._id,
                        trip: this.trip._id
                    }
                });
                await store.dispatch('reviews/save');
                await this.$router.push({ name: 'trip', params: { code: this.$route.params.code } });
                this.formReverse = true;
            },
            close() {
                this.$emit('close')
                this.formReverse = false;
                this.review = {
                    rating: {
                        overall: 0,
                        route: 0,
                        food: 0,
                        accommodation: 0,
                        transport: 0
                    },
                    text: '',
                }
            },
            addPicture(value) {
                this.review.pictures.push(value);
            },
            removePicture(i) {
                this.review.pictures.splice(i, 1);
            }
        }
    }
</script>

<style lang="scss">
    .v-dialog {
        margin: 10px;
    }
    .v-dialog::-webkit-scrollbar {
        width: 6px;
    }
    .v-dialog::-webkit-scrollbar-track {
        -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
        background-color: #f9f9fd;
        border-radius: 10px;
    }
    .v-dialog::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
    }
    .reviewForm {
        position: relative;
        // max-height: 700px;
        max-width: 1000px;
        padding: 38px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        @media all and(max-width: 768px) {
            padding: 24px;
        }
        &__page-name {
            font-weight: 600;
            font-size: 28px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 30px;
        }
        &__raiting{
            &-line {
                display: flex;
                flex-direction: row;
                align-items: center;
                &.main-raiting {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #E5E6E9;
                    margin-bottom: 20px;
                }
                &.other {
                    justify-content: space-between;
                }
            }
            &__key {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #3A4256;
            }
            
        }
        &__additional-raiting {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border: 1px solid #E5E6E9;
            border-radius: 10px;
            min-width: 440px;
            margin: 10px 0 20px;
            // margin-right: 20px;
            @media all and(max-width: 768px) {
                justify-content: center;
                min-width: 100%;
                flex-direction: column;
            }
            &__container {
                display: flex;
                flex-direction: column;
                // overflow: scroll;
                // &::-webkit-scrollbar {
                //     height: 0;
                // }
            }
            &__raiting__key {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #3A4256;
                @media all and(max-width: 768px) {
                    margin-bottom: 20px;
                    text-align: center;
                }
                // max-width: 330px;
            }
            &__delete {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #3A4256;
                cursor: pointer;
            }
        }
        &__save {
            width: 100%;
        }
        &__personal-info {
            font-family: 'Gilroy';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #808080;
            margin-top: 12px;
            margin-left: 5px;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        .img-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: flex-start;
            gap: 10px;
            padding-bottom: 10px;
            @media all and(max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }
        }
        .img_slot {
            position: relative;
            overflow: hidden;
            border-radius: 10px;

            &__picture {
                width: 175px;
                height: 166px;
                background-size: cover;
                background-position: center;
                position: relative;
                border-radius: 10px;
                overflow: hidden;
            }
            &__main-picture {
                position: absolute;
                bottom: 8px;
                right: 8px;
                background: rgba(12, 12, 12, 0.2);
                border-radius: 4px;
                padding: 4px 10px;
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 140%;
                color: #FFF;

                &__button {
                    cursor: pointer;
                }
            }
            &__picture-menu {
                position: absolute;
                top: 8px;
                right: 8px;
            }
        }
    }
    
</style>
