<template>
    <div class="popup-participation" v-if="translates && translates[langUrl]">
        <div class="popup-participation__sended__title">{{ translates[langUrl].title[lang] }}</div>
        <div class="popup-participation__close" @click="close"></div>
        <div class="popup-participation__sended__text" v-html="translates[langUrl].text[lang]"></div>
        <BaseButton
            class="button-fill"
            style="width:300px"
            @click="close"
        >{{ translates[langUrl].button[lang] }}</BaseButton>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';

    import BaseButton from '../../components/common/BaseButton.vue'
    import BaseTextField from '../../components/common/BaseTextField.vue'
    import BaseTextArea from '../../components/common/BaseTextArea.vue'

    export default {
        name: 'PopupParticipation',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea
        },
        data: () => ({
            langUrl: 'popupParticipation'
        }),
        props: {
            text: '',
            comment: '',
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        methods: {
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss">
    .popup-participation {
        position: relative;
        max-width: 600px;
        padding: 50px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 768px) {
            padding: 36px 20px;
            border-radius: 12px;
        }
        &__save {
            width: 100%;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin: 0 auto 12px;
                text-align: center;
            }
            &__text {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin: 0 auto 25px;
                max-width: 500px;
            }
        }
    }
</style>
